var $ = require("jquery");
var breakpoint = require('./app-breakpoints');
require('./app-map');
require("wow");

var wow = new WOW({
	boxClass: 'wow', // default
	animateClass: 'animated', // default
	offset: 50,
	mobile: true, // default
	live: true,
    callback:     function(box) {
        $('body').addClass('loaded');
    },
});
wow.init();
var slick = require("slick");

var lightGallery = require('lightgallery');

var _ = require('underscore');
var _gridSize = breakpoint().match(/(\d+)/g);
var _winSize = $(window).width();
var	_gridLabel = breakpoint().replace(/\d+/g, '').replace(" ", "").replace(/\"/g, "");


$(function() {
	/*$('.carousel').slick({
		autoplay: true,
		responsive: false,
		fade: true,
		dots: false,
		//autoplaySpeed: 8500,
		arrows: false
	});*/

    equalheight('.c-block-_2col.box .items .item .item__wrapper');

	$('.c-slider .items').slick({
		autoplay: true,
		responsive: false,
		fade: true,
		dots: false,
		autoplaySpeed: 8500,
		arrows: false,
		/*responsive: [
			{
				breakpoint: 1479,
				settings: {
					adaptiveHeight: true,
				}
			}

		]*/
	});


	$('.content-clients .items').slick({
		slidesToShow: 5,
		slidesToScroll: 1,
		infinite: true,
        autoplay: true,
        autoplaySpeed: 1500,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,

				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,

				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,

				}
			}
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		]
	});
	$('.content-post .items').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,

	});


	/*$(window).scroll(function() {
		console.log(_gridLabel);

		if(_gridLabel != "smartphone") {
			if ($(this).scrollTop() > sticky){
		   	 $('.c-navigation').addClass("sticky");
			  } else {
			    $('.c-navigation').removeClass("sticky");
			  }
		}
	});*/
    // $(document).on('click', ".c-btn,a[target!='_blank']", function(e) {
    //    /* $( ".c-block,.c-slider,.c-pagetitle" ).each(function( index ) {
    //         $(this).css('animation-name','').addClass('animated').addClass('zoomOut');
    //     });*/
		//
		//
    //    if(!$(this).hasClass('no-loader') && !$(this).parent().hasClass('no-loader')){
    //        $('body').removeClass('loaded');
    //        $( "div:not(.loading)" ).each(function( index ) {
    //            this$ = $(this);
    //            this$.css('animation-name','').addClass('animated').addClass('zoomOut');
		//
		//
    //        });
    //        setTimeout(
    //            function()
    //            {
    //                $('body').addClass('loaded');
		//
    //                $('div').removeClass('zoomOut').addClass('zoomIn');
    //            }, 4000);
	  //  }
		//
    //    // e.preventDefault();
    // });
	$(document).on('click', '.nav-trigger', function(e) {

		$('.nav-trigger').toggleClass('is-active');
		//$('#menu-mobile').slideToggle();
		$('body').toggleClass('menu-open');

		return false;
	});
	$(document).on('click', '.menu-open .l-main', function(e) {
		$('.nav-trigger').toggleClass('is-active');
		$('body').toggleClass('menu-open');


		return false;
	});

    /*$(window).resize(function(){
		_gridSize = breakpoint().match(/(\d+)/g);
		_winSize = $(window).width();
		_margin = (_winSize - _gridSize) / 2;

		_gridLabel = breakpoint().replace(/\d+/g, '').replace(' ','');

		if(_gridLabel == "smartphone") {
			$('.c-navigation').removeClass("sticky");
		}

	}).resize();*/
	$('.delete_cookie').submit(function(e){
		// console.log('submit');
		deleteCookie('passwordId')
		e.preventDefault();

		return false;
	});

	function deleteCookie(cookieName) {
		document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
		location.reload();
	  }

	$('.document_password').submit(function(e){
		// console.log('submit');
		
		ajax_documents();
		e.preventDefault();

		return false;
		
	});

	function ajax_documents(){
		var selected_filter = {};
		// var paged = $paged;

		selected_filter['password'] = $('input[name="password"]').val();
		$('.c-block-_listdocument .c-block__wrapper .title').remove();
		// $('.c-block-_listdocument').addClass('ajax_loading');
	  
		// console.log(selected_filter['password']);
	  
		var data = {
		  action: 'documents_access', // function to execute
		  afp_nonce: afp_vars.afp_nonce, // wp_nonce
		  filters: selected_filter, // selected tag
		//   paged: paged, // selected tag
		  // post_id: $('.c-block-_units').attr('data-url'),
		  // icl_current_language: $('#icl_current_language').val(),
		};
	  
	  
		$.post( afp_vars.afp_ajax_url, data, function(response) {
		  if( response ) {
			// console.log(response);
			// $('.c-block-_listdocument .items').empty();
			// $('.c-block-_listdocument .items').append( response );
			// $('.c-block-_listdocument').removeClass('ajax_loading');
			location.reload();

		  }else{
			console.log('else');
			// console.log(response);
			// location.reload();
			$('.c-block-_listdocument .c-block__wrapper').append( "<div class='title'>"+ afp_vars.documentation_error_msg +"</div>" );
		  };
		});
	  
	  }
});

equalheight = function(container){

    var currentTallest = 0,
        currentRowStart = 0,
        rowDivs = new Array(),
        $el,
        topPosition = 0;
    $(container).each(function() {

        $el = $(this);
        $($el).height('auto')
        topPostion = $el.position().top;

        if (currentRowStart != topPostion) {
            for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPostion;
            currentTallest = $el.height();
            rowDivs.push($el);
        } else {
            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
        }
        for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
        }
    });
}
