var styles=[{featureType:"administrative",elementType:"labels.text.fill",stylers:[{color:"#444444"}]},{featureType:"landscape",elementType:"all",stylers:[{color:"#f2f2f2"}]},{featureType:"poi",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"road",elementType:"all",stylers:[{saturation:-100},{lightness:45}]},{featureType:"road.highway",elementType:"all",stylers:[{visibility:"simplified"}]},{featureType:"road.arterial",elementType:"labels.icon",stylers:[{visibility:"off"}]},{featureType:"transit",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"water",elementType:"all",stylers:[{color:"#46bcec"},{visibility:"on"}]}];

window.initialize = function() {
	var pin = [];
	var infowindow =  new google.maps.InfoWindow({
        content: ""
    });
	var bounds = new google.maps.LatLngBounds();
	var zoom = 13;

	var styledMap = new google.maps.StyledMapType(styles, {name: "custom-map"});


	var options = {
        zoom      : zoom,
       // center    : latLng,
        scrollwheel: false,
        mapTypeId : google.maps.MapTypeId.ROADMAP, // Type de carte, différentes valeurs possible HYBRID, ROADMAP, SATELLITE, TERRAIN
        maxZoom   : 20,
        mapTypeControlOptions: {
            mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
        },
        zoomControl: true
    };

	if($('.pin').length) {
    	$('.pin').each(function(){
			var data = {
	        	"lat": $(this).attr("data-lat"),
	        	"lng": $(this).attr("data-lng"),
	        	"pin": $(this).attr("data-pin"),
                "title": $(this).attr("data-title"),
                // "address": $(this).attr("data-address"),
                "addressgm": $(this).attr("data-addressgm"),
                "website": $(this).attr("data-website"),
                "phone": $(this).attr("data-phone"),
                "email": $(this).attr("data-email"),
                "company": $(this).attr("data-company"),
                "responsable": $(this).attr("data-responsable"),







    		};

    		pin.push(data);
    	});
    }

    if(pin.length) {
    	var lat = pin[0]['lat'];
    	var latLng = new google.maps.LatLng(pin[0]['lat'], pin[0]['lng']);
    	var marker_pin = pin[0]['pin'];
		options.center = latLng;
	}

	map = new google.maps.Map(document.getElementById('google'), options);
	// map.mapTypes.set('map_style', styledMap);
	// map.setMapTypeId('map_style');

	if(pin.length) {
        var lang = jQuery('html')[0].lang;
        var infoWindowContent = [];
        var popup_btn_text = 'Voir la carte';
        var popup_btn_text = '';
        if (lang == 'fr_ca' || lang == 'fr' || lang == 'fr_fr' || lang == 'fr-FR' || lang == 'fr-fr' || lang == 'fr-CA') {
            popup_btn_text = 'Voir la carte';
        } else {
            popup_btn_text = 'See the map';
        };


		for (var i = 0; i < pin.length; i++) {
            var infoWindowContent_item = '';

            infoWindowContent_item += '<div class="map_window">';
            // infoWindowContent_item += '<h4 class="map_window_title uk-h5">' + pin[i]['title'] + ' </h4>';
            infoWindowContent_item += '<h4 class="map_window_title">' + pin[i]['company'] + ' </h4>';


            infoWindowContent_item += '<div class="map_window_body">';
                if(pin[i]['addressgm']) infoWindowContent_item += '<div class="map_window_address">' + pin[i]['addressgm'] + '</div>';
                // if(pin[i]['address']) infoWindowContent_item += '<div class="map_window_address">' + pin[i]['address'] + '</div>';
                if(pin[i]['responsable']) infoWindowContent_item += '<div class="map_window_responsable">' + pin[i]['responsable'] + '</div>';

                if(pin[i]['phone']) infoWindowContent_item += '<div class="map_window_phone">' + pin[i]['phone'] + '</div>';

                if(pin[i]['email']) infoWindowContent_item += '<div class="map_window_email">' + pin[i]['email'] + '</div>';
                if(pin[i]['website']) infoWindowContent_item += '<a class="map_window_website" target="_blank" href="' + pin[i]['website'] + '">' + pin[i]['website'] + '</a> ';
                if(pin[i]['lat'] && pin[i]['lng']) infoWindowContent_item += '<div class="map_window_button"><a class="c-btn c-btn-primary" style="font-style: 18px;" target="_blank" href="https://www.google.com/maps/dir/Current+Location/' + pin[i]['lat'] + ',%20' + pin[i]['lng'] + '">' + popup_btn_text + '</a></div>';
            infoWindowContent_item += '</div>';
            infoWindowContent_item += '</div>';
            infoWindowContent_item = [infoWindowContent_item];
            infoWindowContent.push(infoWindowContent_item);

			var marker = new google.maps.Marker({
				map: map,
				position: new google.maps.LatLng(pin[i]['lat'], pin[i]['lng']),
				icon: new google.maps.MarkerImage(pin[i]['pin']),
                url: pin[i].url,
            });
            //console.log(marker);
            bounds.extend(marker.position);

            google.maps.event.addListener(marker, 'click', (function(marker, i) {
                return function() {
                    //window.open(map, marker);
                    infowindow.setContent(infoWindowContent[i][0]);
				    infowindow.open(map, marker);
                    // window.open('https://www.google.com/maps/dir/Current+Location/'+pin[i]['lat']+',%20'+pin[i]['lng']);
                } 
            })(marker, i));
              
            
		}
	}
    /*google.maps.event.addListener(marker, 'click', function() {
      window.open('https://www.google.com/maps/dir/Current+Location/'+pin[0]['lat']+',%20'+pin[0]['lng']);
    });*/
    map.fitBounds(bounds);

}

function loadScript() {
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp' +
      '&callback=initialize&key=AIzaSyCeChaeRaGEbI84WGOe0FXGsfHUVUSqlJw';
  document.body.appendChild(script);
}

if (document.getElementById("google")) {
    window.onload = loadScript;
}
